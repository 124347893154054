/*
Top navbar custom theme
*/
//set colors for navbar
$navbar-primary-color: $solid-blue-1;
$navbar-text-color: #ffffff;
$navbar-hover-text-color: $gray-lighter;
$navbar-hover-background-color: lighten($navbar-primary-color, 1%);
// set z-index such that datepicker is shown above it, while navbar dropdowns are shown above content
$navbar-z-index: 11;

@mixin main_navbar() {
  position: absolute;
  z-index: $navbar-z-index;
  background-color: $navbar-primary-color;
  border-color: darken($navbar-primary-color, 30%);
  @include gradient-vertical(
    $navbar-primary-color,
    darken($navbar-primary-color, 13%),
    0%,
    100%
  );

  .navbar-brand {
    color: $navbar-text-color;
  }
  .navbar-brand:hover,
  .navbar-brand:focus {
    color: $navbar-hover-text-color;
    background-color: transparent;
  }
  .navbar-text {
    margin-left: 15px;
    margin-right: 15px;
    color: $navbar-text-color;
  }
  .navbar-nav > li > a {
    color: $navbar-text-color;
  }
  .navbar-nav > li > a:hover,
  .navbar-nav > li > a:focus {
    color: $navbar-hover-text-color;
    background-color: transparent;
  }
  .navbar-nav > .active > a,
  .navbar-nav > .active > a:hover,
  .navbar-nav > .active > a:focus {
    color: $navbar-hover-text-color;
    background-color: $navbar-hover-background-color;
    background-image: none;
  }
  .navbar-nav > .disabled > a,
  .navbar-nav > .disabled > a:hover,
  .navbar-nav > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }

  .navbar-nav > .dropdown > a:hover .caret,
  .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: $navbar-hover-text-color;
    border-bottom-color: $navbar-hover-text-color;
  }
  .navbar-nav > .open > a,
  .navbar-nav > .open > a:hover,
  .navbar-nav > .open > a:focus {
    background-color: $navbar-hover-background-color;
    color: $navbar-hover-text-color;
    background-image: none;
  }
  .navbar-nav > .open > a .caret,
  .navbar-nav > .open > a:hover .caret,
  .navbar-nav > .open > a:focus .caret {
    border-top-color: $navbar-hover-text-color;
    border-bottom-color: $navbar-hover-text-color;
  }
  .navbar-nav > .dropdown > a .caret {
    border-top-color: $navbar-text-color;
    border-bottom-color: $navbar-text-color;
  }

  .navbar-link {
    color: $navbar-text-color;
  }
  .navbar-link:hover {
    color: $navbar-hover-text-color;
  }

  .navbar-toggle {
    border-color: $navbar-text-color;
    &:hover,
    &:focus {
      background-color: $navbar-hover-background-color;
    }
    .icon-bar {
      background-color: $navbar-text-color;
    }
  }
  // when navbar is collapsed, style dropdown to look ok on navbar background
  @media (max-width: $grid-float-breakpoint-max) {
    .open .dropdown-menu {
      background-color: $dropdown-bg;
    }
  }

  // align plandela logo
  .logo-image {
    height: 36px;
    margin-top: -8px;
    margin-left: -5px;
    vertical-align: top;
  }

  .dropdown-text {
    padding: 10px;
  }

  .right-dropdown ul {
    min-width: 200px;
  }

  .alerts-dropdown ul {
    min-width: 400px;
  }
}
