// import bootstrap customizations
@import 'variables';
// import actual bootstrap styles once for whole application
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/theme';
@import 'navbar';

.navbar.navbar-custom {
  @include main_navbar();
}

html {
  height: 100%;
}

#container {
  position: fixed;
  top: 51px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
  margin-bottom: 30px;
}

// increase form controls on smaller devices to prevent
// safari auto-zoom behaviour on focus
// related to: #4183.
@media (max-width: $grid-float-breakpoint-max) {
  .form-control {
    font-size: 16px;
    height: 38px;
  }
}

.page-footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;

  line-height: 30px;
  background-color: darken($solid-blue-1, 5%);
  text-align: center;
  color: white;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: white;
    text-decoration: none;
  }

  box-shadow: 0 -3px 3px -3px #999;

  svg {
    fill: #ffffff;
  }

  .link-tw {
    position: relative;
    top: 4px;
    padding-left: 3px;
  }

  .link-tw svg {
    height: 16px;
    width: 16px;
  }

  .link-fb {
    position: relative;
    top: 1px;
  }

  .link-fb svg {
    height: 13px;
    width: 13px;
  }

  .link-ln {
    position: relative;
    top: 3px;
  }

  .link-ln svg {
    height: 15px;
    width: 15px;
  }
}

#login-form form {
  margin-top: 5em;

  @media (max-height: 700px) {
    margin-top: 3em;
  }
}

.unsupported-browser-container {
  @include box-shadow(1px 1px 1px rgba(0, 0, 0, 0.3));
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: auto;

  .content {
    display: flex;
    overflow: hidden;
    flex: 1;
    font-size: 18px;
    padding: 15px;
  }

  .alerts-item-icon {
    justify-content: center;
    align-items: center;
    padding-right: 10px;
    margin-right: 10px;
    border-right: 2px solid #eeeeee;
  }
}

.unsupported-browser-deprecated {
  color: white;
  background-color: $brand-warning;
}

.unsupported-browser-unsupported {
  color: white;
  background-color: $brand-danger;
}
