@import '~bootstrap-sass/assets/stylesheets/bootstrap/mixins';

$solid-blue-1: #0057a5;
$grid-selected-background-color: lighten($solid-blue-1, 45%);
$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
// add Segoe UI Symbol font to list to make choice of fonts for unicode symbols
// on windows more uniform (i.e. so that chrome and firefox do not fallback to
// different fonts)
// Add Noto Sans to fix vertical alignment issues on linux/firefox #5185
$font-family-sans-serif: 'Helvetica Neue', 'Noto Sans', Helvetica, Arial,
  'Segoe UI Symbol', sans-serif;
$brand-primary: lighten($solid-blue-1, 5%) !default;

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
